;(() => {
  const toggleClass = 'has-shadow'
  $(window).on('scroll', () => {
    if (window.scrollY > 0) {
      $('[data-header]').addClass(toggleClass)
    } else {
      $('[data-header]').removeClass(toggleClass)
    }
  })
})()

// カルーセルに関する処理
// @see https://kenwheeler.github.io/slick/
;(() => {
  $('[data-agent-carousel]').slick({
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    prevArrow: "<button type='button' class='solution-section__carousel-arrow is-prev'></button>",
    nextArrow: "<button type='button' class='solution-section__carousel-arrow is-next'></button>"
  })
})()

// FAQの開閉に関する処理
;(() => {
  $('[data-faq-item-question]').on('click', function () {
    const $targetItem = $(this).closest('[data-faq-item]')[0]
    $($targetItem).toggleClass('is-open')
  })
})()
